import React from 'react';
import ReactDOM from 'react-dom';
import DropzoneComponent from 'react-dropzone-component';
import InputFile from './components/InputFile';
import BulletinCommentPanel from './components/CommentPanel';
import UrgentBoardCommentPanel from './components/UrgentBoardCommentPanel';
import BulletinCommentList from './components/CommentList';
import UrgentBoardCommentList from './components/UrgentBoardCommentList';
import InputThumbnail from './components/InputThumbnail';
import UrgentBoardAddPanel from './components/UrgentBoardAddPanel';


window.InputFiles = function (aOptions) {
    console.log("aOptions", aOptions);

    let inputName = aOptions.inputName,
        multiple = aOptions.multiple,
        files = aOptions.files || [],
        fetchUrl = aOptions.fetchUrl,
        uploadUrl = aOptions.uploadUrl || Consts.FILE_UPLOAD_URL,
        fileUrlBase = aOptions.fileUrlBase || Consts.FILE_URL_BASE,
        fileRepo = aOptions.fileRepo || Consts.FILE_REPO,
        container = aOptions.container || inputName + "Container";

    console.log("inputName >> ", inputName);
    console.log("fileRepo >> ", fileRepo);

    ReactDOM.render(
        <InputFile
            inputName={inputName}
            files={files}
            fetchUrl={fetchUrl}
            uploadUrl={uploadUrl}
            fileUrlBase={fileUrlBase}
            fileRepo={fileRepo}
            multiple={multiple}
        />,
        document.getElementById(container)
    );
    //$('#'+ inputName + 'Container').magnificPopup(gApp.MAGNIFIC_POPUP_OPTION);
};

window.InputThumbnail = function (aOptions) {
    console.log("aOptions", aOptions);

    let inputName = aOptions.inputName,
        file = aOptions.file,
        uploadUrl = aOptions.uploadUrl || Consts.FILE_UPLOAD_URL,
        container = aOptions.container || inputName + "Container";

    console.log("inputName >> ", inputName);
    console.log("file >> ", file);

    ReactDOM.render(
        <InputThumbnail
            inputName={inputName}
            file={file}
            uploadUrl={uploadUrl}
        />,
        document.getElementById(container)
    );
};

window.FileDropZones = function (componentId, uploadUrl) {
    "use strict";

    let componentConfig = {
        iconFiletypes: ['.jpg', '.png', '.gif'],
        showFiletypeIcon: true,
        postUrl: uploadUrl
    };

    let eventHandlers = {
        // This one receives the dropzone object as the first parameter
        // and can be used to additional work with the dropzone.js
        // object
        init: null,
        // All of these receive the event as first parameter:
        drop: callbackArray,
        dragstart: null,
        dragend: null,
        dragenter: null,
        dragover: null,
        dragleave: null,
        // All of these receive the file as first parameter:
        addedfile: simpleCallBack,
        removedfile: null,
        thumbnail: null,
        error: null,
        processing: null,
        uploadprogress: null,
        sending: null,
        success: null,
        complete: null,
        canceled: null,
        maxfilesreached: null,
        maxfilesexceeded: null,
        // All of these receive a list of files as first parameter
        // and are only called if the uploadMultiple option
        // in djsConfig is true:
        processingmultiple: null,
        sendingmultiple: null,
        successmultiple: null,
        completemultiple: null,
        canceledmultiple: null,
        // Special Events
        totaluploadprogress: null,
        reset: null,
        queuecomplete: null
    };

    React.render(
        <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
        />,
        document.getElementById(componentId)
    );
};

window.BulletinCommentLists = function (aOptions) {
    console.log("BulletinCommentLists aOptions=", aOptions);
    console.log("bulletinUid=", aOptions.bulletinUid);
    let listInputName = aOptions.listInputName,
        bulletinUid = aOptions.bulletinUid,
        pageUid = aOptions.pageUid,
        commentPanelName = aOptions.commentPanelName,
        comments = aOptions.comments || [],
        container = aOptions.container || commentPanelName + "Container";
    ReactDOM.render(
        <BulletinCommentList
            inputName={listInputName}
            bulletinUid={bulletinUid}
            comments={comments}
            pageUid={pageUid}
        />,
        document.getElementById(container)
    );
};

window.BulletinCommentPanels = function (aOptions) {
    console.log("BulletinCommentPanels aOptions=", aOptions);
    console.log("bulletinUid=", aOptions.bulletinUid);
    let formInputName = aOptions.formInputName,
        listInputName = aOptions.listInputName,
        commentPanelName = aOptions.commentPanelName,
        bulletinUid = aOptions.bulletinUid,
        pageUid = aOptions.pageUid,
        container = aOptions.container || commentPanelName + "Container";
    ReactDOM.render(
        <BulletinCommentPanel
            formInputName={formInputName}
            listInputName={listInputName}
            bulletinUid={bulletinUid}
            pageUid={pageUid}
        />,
        document.getElementById(container)
    );
};

window.UrgentBoardCommentLists = function (aOptions) {
    console.log("UrgentBoardCommentLists aOptions=", aOptions);
    let listInputName = aOptions.listInputName,
        urgentBoardUid = aOptions.urgentBoardUid,
        publicComment = aOptions.publicComment,
        pageUid = aOptions.pageUid,
        commentPanelName = aOptions.commentPanelName,
        comments = aOptions.comments || [],
        username = aOptions.username,
        memberType = aOptions.memberType,
        container = aOptions.container || commentPanelName + "Container";
    ReactDOM.render(
        <UrgentBoardCommentList
            inputName={listInputName}
            urgnetBoardUid={urgentBoardUid}
            comments={comments}
            pageUid={pageUid}
            username={username}
            memberType={memberType}
            publicComment={publicComment}
        />,
        document.getElementById(container)
    );
};

window.UrgentBoardCommentPanels = function (aOptions) {
    console.log("UrgentBoardCommentPanels aOptions=", aOptions);
    let formInputName = aOptions.formInputName,
        listInputName = aOptions.listInputName,
        commentPanelName = aOptions.commentPanelName,
        urgentBoardUid = aOptions.urgentBoardUid,
        publicComment = aOptions.publicComment,
        pageUid = aOptions.pageUid,
        container = aOptions.container || commentPanelName + "Container",
        id = aOptions.id,
        username = aOptions.username,
        memberType = aOptions.memberType;
    ReactDOM.render(
        <UrgentBoardCommentPanel
            formInputName={formInputName}
            listInputName={listInputName}
            urgentBoardUid={urgentBoardUid}
            pageUid={pageUid}
            id={id}
            username={username}
            memberType={memberType}
            publicComment={publicComment}
        />,
        document.getElementById(container)
    );
};

window.UrgentBoardAddPanels = function (aOptions) {
    console.log("UrgentBoardAddPanels aOptions=", aOptions);
    let formInputName = aOptions.formInputName,
        listInputName = aOptions.listInputName,
        commentPanelName = aOptions.commentPanelName,
        urgentBoardUid = aOptions.urgentBoardUid,
        publicComment = aOptions.publicComment,
        pageUid = aOptions.pageUid,
        container = aOptions.container || commentPanelName + "Container",
        id = aOptions.id,
        username = aOptions.username,
        memberType = aOptions.memberType;
    ReactDOM.render(
        <UrgentBoardAddPanel
            formInputName={formInputName}
            listInputName={listInputName}
            urgentBoardUid={urgentBoardUid}
            pageUid={pageUid}
            id={id}
            username={username}
            memberType={memberType}
            publicComment={publicComment}
        />,
        document.getElementById(container)
    );
};