"use strict";
import React from 'react';

class BulletinCommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        const pageUid = this.props.pageUid;
        const bulletinUid = this.props.bulletinUid;

        e.preventDefault();
        // var commentText = React.findDOMNode(this.refs.commentText).value.trim();
        const $commentTextElem = $('input[name="commentText"]');
        var commentText = $.trim($commentTextElem.val());
        if (!commentText) {
            alert("내용을 입력하세요.");
            return;
        }
        const bulletinComment = "{\"bulletinUid\":" + bulletinUid + ", \"commentText\":\"" + commentText + "\"}";
        console.log('bulletinComment = ', bulletinComment);
        $.ajax({
            url: "/pages/" + pageUid + "/bulletins/" + bulletinUid + "/comments",
            dataType: 'json',
            type: 'POST',
            data: JSON.parse(bulletinComment),
            success: function (comment) {
                console.log(comment);
                this.props.onUpdate(true);
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });

        // React.findDOMNode(this.refs.commentText).value = '';
        $commentTextElem.val('');

        return false;
    }

    render() {
        const bulletinUid = this.props.bulletinUid;
        return (
            <form className="commentForm" onSubmit={this.handleSubmit}>
                <input type="hidden" value={bulletinUid} name="bulletinUid"/>
                <div className="form-group form-group--h">
                    <input type="text" className="form-control" id="reply_answer" name="commentText"
                                                                 placeholder="답변 내용을 입력하세요." ref="commentText" />
                </div>
                <div><input type="submit" className="btn btn-completion btn--flat btn--save input_btn" value="입력"/></div>
            </form>
        )
    }
}
;

export default BulletinCommentForm;

