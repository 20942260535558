"use strict";
import React from 'react';

function Comment(props) {
    const {commentText, creDateTime} = props.comment;

    return (
        <div id="answer_past">
            <div>
                <p className="answer_text">{commentText}</p>
                <p className="answer_date">{DateTimes.format(creDateTime)}</p>
            </div>
        </div>
    )
}

class BulletinCommentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: this.props.comments
        };
    }

    componentDidMount() {
        const pageUid = this.props.pageUid;
        const bulletinUid = this.props.bulletinUid;

        $.ajax({
            url: "/pages/" + pageUid + "/bulletins/" + bulletinUid + "/comments",
            dataType: 'json',
            cache: 'false',
            success: function (comments) {
                console.log("comments=", comments);
                this.setState({ comments: comments });
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });
    }

    componentWillReceiveProps(nextProps) {
        const updateComments = nextProps.comments;
        this.setState({
            comments: updateComments
        });
    }

    render() {
        const { comments } = this.state;
        if (!comments || comments.length == 0) {
            return null;
        }
        return (
            <div className="form-body table-responsive">
            {
                comments.map((comment, i) => (
                    <Comment comment={comment} key={i}>
                    </Comment>
                ))
            }
            </div>
        )
    }
}
;

export default BulletinCommentList;
