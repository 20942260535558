"use strict";
import React from 'react';
import BulletinCommentForm from './CommentForm';
import BulletinCommentList from './CommentList';

class BulletinCommentPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateComments: []
        };
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate(isUpdate) {
        if (isUpdate) {
            const bulletinUid = this.props.bulletinUid;
            const pageUid = this.props.pageUid;
            $.ajax({
                url: "/pages/" + pageUid + "/bulletins/" + bulletinUid + "/comments",
                dataType: 'json',
                cache: 'false',
                success: function (comments) {
                    console.log("comments=", comments);
                    this.setState({ updateComments: comments });
                }.bind(this),
                error: function (xhr, status, err) {
                    console.error(this.props.url, status, err.toString());
                }.bind(this)
            });
        }
    }

    render() {
        const { updateComments } = this.state;
        const { formInputName, listInputName, bulletinUid, pageUid } = this.props;

        return (
            <div className="commentPanel">
                <BulletinCommentForm
                    inputName={formInputName}
                    bulletinUid={bulletinUid}
                    onUpdate={this.handleUpdate}
                    pageUid={pageUid}
                />

                <BulletinCommentList
                    inputName={listInputName}
                    bulletinUid={bulletinUid}
                    comments={updateComments}
                    pageUid={pageUid}
                />
            </div>
        )
    }
};

export default BulletinCommentPanel;
