"use strict";
import React from 'react';
import Files from './Files';

class InputFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.files || [],
        };
        this.handleFile = this.handleFile.bind(this);
    }
    handleFile(e) {
        var files = e.target.files;
        var _this = this;

        console.log("files", files);
        console.log("props", this.props);
        console.log("target", e.target);

        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            console.log("props >", this.props);
            console.log("file >", file);
            var formData = new FormData();
            formData.append('file', file, file.name);
            $.ajax({
                url: this.props.uploadUrl,
                data: formData,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function(uploadedFile) {
                    console.log('uploadedFile=', uploadedFile);
                    let files = _this.state.files;
                    files.push(uploadedFile);
                    _this.setState({files: files});
                },
                error: function() {
                    console.error('fileupload error');
                },
            });
        };

    }
    render() {

        return (
            <div className="fileUpload">
                {this.props.multiple?
                    <input type="file" className="filestyle" data-input="false" data-icon="false" data-buttonText="파일선택" multiple onChange={this.handleFile} />
                    :
                    <input type="file" className="filestyle" data-input="false" data-icon="false" data-buttonText="파일선택" onChange={this.handleFile} />
                }
                <Files inputName={this.props.inputName}
                       files={this.state.files}
                       fileRepo={this.props.fileRepo}
                       fileUrlBase={this.props.fileUrlBase}
                       fetchUrl={this.props.fetchUrl}
                       enableDel={true}
                />
            </div>
        );
    }
};

export default InputFile;