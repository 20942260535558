"use strict";
import React from 'react';

class Thumbnail extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { inputName, file } = this.props;
        console.log("file2", file);
        return (
            <div className="fileList">
                {file ?
                    <input type="hidden" name={inputName} value={file.oid} />
                    :
                    <input type="hidden" name={inputName} />
                }
            </div>
        );
    }
};

export default Thumbnail;