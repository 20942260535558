"use strict";
import React from 'react';

class UrgentBoardAddForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value? props.value : '',
            className: props.className? props.className : '',
            error: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        const pageUid = this.props.pageUid;
        const urgentBoardUid = this.props.urgentBoardUid;
        const { id, username, memberType, publicComment } = this.props;

        e.preventDefault();
        // var commentText = React.findDOMNode(this.refs.commentText).value.trim();
        const $commentTextElem = $('input[name="commentText"]');
        let commentText = $.trim($commentTextElem.val());
        if (!commentText) {
            alert("내용을 입력하세요.");
            return;
        }
        console.log("this.props=", this.props);
        // const urgentBoardComment = "{\"urgentBoardUid\":" + urgentBoardUid + ", \"commentText\":\"" + commentText + "\"}";
        const urgentBoardComment = "{\"urgentBoardUid\":" + urgentBoardUid + ", \"commentText\":\"" + commentText + "\"" + ", \"id\":\"" + id + "\"" + ", \"username\":\"" + username + "\"" + ", \"memberType\":\"" + memberType +"\"}";
        console.log('urgentBoardComment = ', urgentBoardComment);
        $.ajax({
            url: "/pages/" + pageUid + "/urgentBoards/" + urgentBoardUid + "/comments",
            dataType: 'json',
            type: 'POST',
            data: JSON.parse(urgentBoardComment),
            success: function (comment) {
                console.log(comment);
                this.props.onUpdate(true);
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });

        // React.findDOMNode(this.refs.commentText).value = '';
        $commentTextElem.val('');

        return false;
    }

    render() {
        const urgentBoardUid = this.props.urgentBoardUid;
        const { id, username, memberType, publicComment } = this.props;
        return (
            <main role="main" className="post">
                <header>
                    <ol className="breadcrumb">
                        <li><a className="" href="">Question</a></li>
                        <li className="active"><a href="">긴급의뢰 요청</a></li>
                    </ol>
                </header>
                <form className="form form--box form--edit" id="questionWriteForm" action="/m/board/urgent/bulletins/form" method="post" role="form" onSubmit={this.handleSubmit}>
                    <div className="form-group form-group--h">
                        <div className="form-group form-group--h label-floating">
                            <div className="form-field">
                                <label className="control-label" htmlFor="subjectField">제목</label>
                                <input type="text" className="form-control" id="subjectField"
                                    name="subject" placeholder=""
                                value="" />
                            </div>
                        </div>
                        <div className="form-group form-group--w label-floating">
                            <div className="form-field">
                                <label className="control-label" htmlFor="bodyTextField">본문</label>
                            <textarea className="form-control" id="bodyTextField" name="bodyText" placeholder=""/>
                        </div>
                    </div>
                    <div className="form-group form-group--h label-floating">
                        <label className="mdl-switch mdl-js-switch mdl-js-ripple-effect" htmlFor="publicComment">
                            <span className="mdl-switch__label">회원만 댓글허용</span>
                            <input type="checkbox" id="publicComment" name="publicComment" className="mdl-switch__input" value="1"/>
                        </label>
                    </div>
                    </div>
                    <div className="form-footer clearfix">
                        <div className="form-footer clearfix">
                            <button className="mdl-button mdl-js-button mdl-button--raised mdl-button--accent">등록</button>
                    </div>
                </div>
                </form>
            </main>
        )
    }
}


export default UrgentBoardAddForm;

