"use strict";
import React from 'react';

class File extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        };
        this.delete = this.delete.bind(this);
    }
    delete(e) {
        this.setState({show: false});
    }
    render() {
        //if (!this.state.show) return null;

        const { inputName, file, fileRepo, fileUrlBase, enableDel } = this.props;
        const fileUrl = (fileUrlBase ? fileUrlBase : "") + "/" + file.oid + (fileRepo ? "?fileRepo=" + fileRepo : "");
        console.log("file", file);
        return (
            <li className="file">
                <a className="fileName" href={fileUrl}>{file.fileName}</a>
                {enableDel ?
                    <button className="btn--delete" onClick={this.delete}><i className="fa fa-close" aria-hidden="true"></i></button> : null
                }
                {inputName ?
                    <input type="hidden" name={inputName} value={file.oid} /> : null
                }
            </li>
        );
    }
};




class Files extends React.Component {
    render() {
        if (!this.props.files) return null;
        const { inputName, files, fileRepo, fileUrlBase, enableDel } = this.props;
        return (
            <ul className="fileList">
                {files.map((file, i) => (
                    <File inputName={inputName}
                          file={file}
                          fileRepo={fileRepo}
                          fileUrlBase={fileUrlBase}
                          enableDel={enableDel}
                          key={i}
                    />)
                )}
            </ul>
        );
    }
};


export default Files;