"use strict";
import React from 'react';
import Thumbnail from './Thumbnail';

class InputThumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: this.props.file,
            imagePreviewUrl: ''
        };
        this.handleFile = this.handleFile.bind(this);
    }
    handleFile(e) {
        var file = e.target.files[0];
        var _this = this;
        var reader = new FileReader();
        var formData = new FormData();
        formData.append('file', file, file.name);

        reader.onloadend = () => {
            $.ajax({
                url: this.props.uploadUrl,
                data: formData,
                dataType: 'json',
                cache: false,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function(uploadedFile) {
                    console.log('uploadedFile=', uploadedFile);
                    _this.setState({
                        file: uploadedFile,
                        imagePreviewUrl: reader.result
                    });
                    $('.thumbnail-outerframe img').attr('src', _this.state.imagePreviewUrl);
                },
                error: function () {
                    console.error('fileupload error');
                },
            });
        };

        reader.readAsDataURL(file);
    }
    render() {
        console.log("file1", this.state.file);
        return (
            <label className="fileUpload" htmlFor="thumbnailField">
                <i className="material-icons">camera_alt</i>
                <input type="file" className="thumbnail_label" name="none" data-input="false" data-icon="icon-plus"
                       onChange={this.handleFile} id="thumbnailField" />
                <Thumbnail inputName={this.props.inputName}
                           file={this.state.file}
                />
            </label>
        )
    }
};

export default InputThumbnail;