"use strict";
import React from 'react';

function Comment(props) {
    const {commentText, creDateTime, username, id, memberType} = props.comment;
    const {currentId, currentMT} = props;
    // console.log("Comment props", props.comment);
    function handleDelete(e) {
        console.log("handleDelete uid:{} +++", id);
    }
    return (
        <div id="answer_past">
            <div>
                <p className="answer_text">{commentText}</p>
                <p className="answer_date">
                    {(memberType !== null && memberType !== "") ? <a href={"/m/" + memberType + "s/id/"+username} style={{color: "#D39D97"}}>
                        {username}{memberType === 'imo'? '이모 ' : memberType === 'user'? '의뢰인 ' : null }</a> : '비회원 '}
                        | {DateTimes.format(creDateTime)}
                    {/*(currentId === username && currentMT === memberType) ? <span onClick={handleDelete()}> | 삭제하기</span> : null */}
                </p>
            </div>
        </div>
    )
}

class UrgentBoardCommentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: this.props.comments,
            currentId: this.props.username,
            currentMT: this.props.memberType
        };
    }

    componentDidMount() {
        const pageUid = this.props.pageUid;
        const urgentBoardUid = this.props.urgentBoardUid;
        console.log("this.props=", this.props);

        $.ajax({
            url: "/pages/" + pageUid + "/urgentBoards/" + urgentBoardUid + "/comments",
            dataType: 'json',
            cache: 'false',
            success: function (comments) {
                console.log("comments=", comments);
                this.setState({ comments: comments });
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });
    }

    componentWillReceiveProps(nextProps) {
        const updateComments = nextProps.comments;
        this.setState({
            comments: updateComments
        });
    }

    render() {
        const { comments, currentId, currentMT } = this.state;
        if (!comments || comments.length == 0) {
            return null;
        }
        return (
            <div className="form-body table-responsive">
            {
                comments.map((comment, i) => (
                    <Comment comment={comment} key={i} currentId={currentId} currentMT={currentMT}>
                    </Comment>
                ))
            }
            </div>
        )
    }
}
;

export default UrgentBoardCommentList;