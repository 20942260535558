"use strict";
import React from 'react';

class UrgentBoardCommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        const pageUid = this.props.pageUid;
        const urgentBoardUid = this.props.urgentBoardUid;
        const { id, username, memberType, publicComment } = this.props;

        e.preventDefault();
        // var commentText = React.findDOMNode(this.refs.commentText).value.trim();
        const $commentTextElem = $('input[name="commentText"]');
        var commentText = $.trim($commentTextElem.val());
        if (!commentText) {
            alert("내용을 입력하세요.");
            return;
        }
        console.log("this.props=", this.props);
        // const urgentBoardComment = "{\"urgentBoardUid\":" + urgentBoardUid + ", \"commentText\":\"" + commentText + "\"}";
        const urgentBoardComment = "{\"urgentBoardUid\":" + urgentBoardUid + ", \"commentText\":\"" + commentText + "\"" + ", \"id\":\"" + id + "\"" + ", \"username\":\"" + username + "\"" + ", \"memberType\":\"" + memberType +"\"}";
        console.log('urgentBoardComment = ', urgentBoardComment);
        $.ajax({
            url: "/pages/" + pageUid + "/urgentBoards/" + urgentBoardUid + "/comments",
            dataType: 'json',
            type: 'POST',
            data: JSON.parse(urgentBoardComment),
            success: function (comment) {
                console.log(comment);
                this.props.onUpdate(true);
            }.bind(this),
            error: function (xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });

        // React.findDOMNode(this.refs.commentText).value = '';
        $commentTextElem.val('');

        return false;
    }

    render() {
        const urgentBoardUid = this.props.urgentBoardUid;
        const { id, username, memberType, publicComment } = this.props;
        if(publicComment === "1" && memberType === ""){
            return (
                <div className="form-group form-group--h">
                    <div className="form-control">로그인 후 댓글 작성가능</div>
                </div>
            )
        }
        else{
            return (
                <form className="commentForm" onSubmit={this.handleSubmit}>
                    <input type="hidden" value={urgentBoardUid} name="urgentBoardUid"/>
                    <input type="hidden" value={id} name="id"/>
                    <input type="hidden" value={username} name="username"/>
                    <input type="hidden" value={memberType} name="memberType"/>
                    <div className="form-group form-group--h">
                        <input type="text" className="form-control" id="reply_answer" name="commentText"
                               placeholder="답변 내용을 입력하세요." ref="commentText" />
                    </div>
                    <div><input type="submit" className="btn btn-completion btn--flat btn--save input_btn" value="입력"/></div>
                </form>
            )
        }
    }
}
;

export default UrgentBoardCommentForm;

