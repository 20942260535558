"use strict";
import React from 'react';
import UrgentBoardCommentForm from './UrgentBoardCommentForm';
import UrgentBoardCommentList from './UrgentBoardCommentList';

class UrgentBoardCommentPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateComments: []
        };
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate(isUpdate) {
        if (isUpdate) {
            const urgentBoardUid = this.props.urgentBoardUid;
            const { id, username, memberType } = this.props;
            const pageUid = this.props.pageUid;
            console.log("this.props=", this.props);
            $.ajax({
                url: "/pages/" + pageUid + "/urgentBoards/" + urgentBoardUid + "/comments",
                dataType: 'json',
                cache: 'false',
                success: function (comments) {
                    console.log("comments=", comments);
                    this.setState({ updateComments: comments });
                }.bind(this),
                error: function (xhr, status, err) {
                    console.error(this.props.url, status, err.toString());
                }.bind(this)
            });
        }
    }

    render() {
        const { updateComments } = this.state;
        const { formInputName, listInputName, urgentBoardUid, pageUid, id, username, memberType, publicComment } = this.props;
        return (
            <div className="commentPanel">
                <UrgentBoardCommentForm
                    inputName={formInputName}
                    urgentBoardUid={urgentBoardUid}
                    onUpdate={this.handleUpdate}
                    pageUid={pageUid}
                    id={id}
                    username={username}
                    memberType={memberType}
                    publicComment={publicComment}
                />

                <UrgentBoardCommentList
                    inputName={listInputName}
                    urgentBoardUid={urgentBoardUid}
                    comments={updateComments}
                    pageUid={pageUid}
                    username={username}
                    memberType={memberType}
                />
            </div>
        )
    }
};

export default UrgentBoardCommentPanel;
